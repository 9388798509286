import React, { ReactElement } from 'react'
import AboutUsLayout from '../../components/Layout/AboutUs'

import SEO from '../../components/SEO/SEO'
import Content from '../../components/Content'
import ContentNav from '../../components/ContentNav'

import { graphql, useStaticQuery } from 'gatsby'

const links = [
  {
    name: 'Privacy Notice',
    url: '/privacy-notice'
  },
  {
    name: 'Terms and Conditions',
    url: '/terms-and-conditions'
  }
]

const TermsAndConditions = (): ReactElement => {
  const termsAndConditionData = useStaticQuery(graphql`
    query {
      termsAndConditions {
        id
        name
        containers {
          name
          field_collections {
            name
            fields {
              name
              value
            }
          }
        }
      }
      heroBanner(title: { eq: "Terms" }) {
        image
      }
    }
  `)

  const termsAndConditions = termsAndConditionData.termsAndConditions.containers[0].field_collections[0].fields

  const title = termsAndConditions ? termsAndConditions[0].value : ''
  const body = termsAndConditions && termsAndConditions[1] ? termsAndConditions[1].value : ''

  return (
    <AboutUsLayout heroImage={termsAndConditionData.heroBanner ? termsAndConditionData.heroBanner.image : ''}>
      <SEO title="Terms and Conditions - ALISI | Ayala Land" />
      <ContentNav links={links} active={1} />
      <Content>
        <h1>{title}</h1>

        <div
          dangerouslySetInnerHTML={{
            __html: body
          }}
        />
      </Content>
    </AboutUsLayout>
  )
}

export default TermsAndConditions
